export const getColor = (value) => {
    // Percentage case
    if (value.includes("%")) {
        // Remove '%' sign and convert to float
        var percentage = parseFloat(value.replace("%", "").trim());

        if (percentage < 50) {
            return "red";
        } else if (percentage >= 50 && percentage <= 75) {
            return "yellow";
        } else {
            return "green";
        }
    } else {
        // Text case
        var text_values_green = ['Excelente', 'Recomendado'];
        var text_values_yellow = ['Bueno', 'Regular', 'Indeterminado por homonimias', 'Recomendado con reservas'];
        var text_values_red = ['Discutible', 'Dudoso', 'No recomendado'];

        if (text_values_green.includes(value)) {
            return "green";
        } else if (text_values_yellow.includes(value)) {
            return "yellow";
        } else if (text_values_red.includes(value)) {
            return "red";
        } else {
            return "gray";
        }
    }
}

export const isClientSpecial = (user_company) => {
    const specialCompanies = ['BANORTE', 'DHL', 'DHL EXPRESS', 'SANTANDER', 'TUIIO - SANTANDER', 'OXXO', 'ICONN', 'HEINEKEN', 'HEINEKEN MEXICO'];
    return specialCompanies.includes(user_company);
}

export const g_section_formats_non_special = ['Historial_laboral_porcentaje', 'Historial_Legal_Mensaje', 'SLcore', 'Comportamiento_Personal_porcentaje']
export const g_section_formats_special = ['Historial_Legal_Mensaje', 'Comportamiento_Personal_porcentaje']

export const processUserInfoCookie = (userCompany) => {
    let light_on_oxxo = false;
    let iconn_logic = false;
    let oxxo_logic = false;
    let dhl_logic = false;
    let banorte = false;
    let light_on = false;

    if (userCompany) {
        if (userCompany === 'BANORTE') {
            banorte = true;
            light_on = true;
        } else if (['SANTANDER', 'TUIIO - SANTANDER'].includes(userCompany)) {
            light_on = true;
        } else if (['HEINEKEN', 'HEINEKEN MEXICO'].includes(userCompany)) {
            light_on = true;
        } else if (userCompany === 'OXXO') {
            light_on_oxxo = true;
            oxxo_logic = true;
        } else if (userCompany === 'ICONN') {
            light_on_oxxo = true;
            iconn_logic = true;
        } else if (["DHL", "DHL EXPRESS"].includes(userCompany)) {
            light_on_oxxo = true;
            dhl_logic = true;
        }
    }

    return {
        light_on_oxxo,
        iconn_logic,
        oxxo_logic,
        dhl_logic,
        banorte,
        light_on,
    };
}

export const trafficLightSection = (oxxo_logic, iconn_logic, dhl_logic, data) => {
    if (!oxxo_logic && !iconn_logic && !dhl_logic) {
        if (["Recomendado", "Excelente"].includes(data['Historial_Legal_Mensaje'])) {
            return 'green';
        } else if (data['Historial_Legal_Mensaje'] === 'Recomendado con reservas') {
            return 'yellow';
        } else {
            return 'red';
        }
    } else if (oxxo_logic && !iconn_logic && !dhl_logic) {
        if (data['OXXO_Algorithm'] === 'Verde') {
            return 'green';
        } else if (data['OXXO_Algorithm'] === 'Amarillo') {
            return 'yellow';
        } else if (data['OXXO_Algorithm'] === 'Rojo') {
            return 'red';
        }
    } else if (!oxxo_logic && iconn_logic && !dhl_logic) {
        if (data['ICONN_Algorithm'] === 'Verde') {
            return 'green';
        } else if (data['ICONN_Algorithm'] === 'Amarillo') {
            return 'yellow';
        } else if (data['ICONN_Algorithm'] === 'Rojo') {
            return 'red';
        }
    } else if (!oxxo_logic && !iconn_logic && dhl_logic) {
        if (data['DHL_Algorithm'] === 'Verde') {
            return 'green';
        } else if (data['DHL_Algorithm'] === 'Amarillo') {
            return 'yellow';
        } else if (data['DHL_Algorithm'] === 'Rojo') {
            return 'red';
        }
    }
}

export const processCompanyData = (companyName, finalData) => {
    const upperCompanyName = companyName.toUpperCase();
    if (!isClientSpecial(upperCompanyName)) {
        const gSectionFormats = ['Historial_laboral_porcentaje', 'Historial_Legal_Mensaje', 'SLcore', 'Comportamiento_Personal_porcentaje'];

        for (const format of gSectionFormats) {
            finalData[format + '_traffic_light'] = getColor(finalData[format]);

            if (upperCompanyName === 'FARMACIAS DEL AHORRO') {
                if (format === 'Comportamiento_Personal_porcentaje') {
                    if (finalData['Historial_Legal_Mensaje_traffic_light'] === 'red') {
                        finalData[format + '_traffic_light'] = 'red';
                    } else if (finalData['Historial_Legal_Mensaje_traffic_light'] === 'yellow') {
                        finalData[format + '_traffic_light'] = 'yellow';
                    } else {
                        const fs = ['Historial_laboral_porcentaje_traffic_light', 'Historial_Legal_Mensaje_traffic_light', 'SLcore_traffic_light', 'Policitamente_expuesto_porcentaje_traffic_light'];
                        const redCount = fs.filter(x => finalData[x] === 'red').length;
                        const yellowCount = fs.filter(x => finalData[x] === 'yellow').length;

                        if (redCount >= 2) {
                            finalData[format + '_traffic_light'] = 'red';
                        } else if (redCount === 1) {
                            finalData[format + '_traffic_light'] = 'yellow';
                        } else if (yellowCount >= 2) {
                            finalData[format + '_traffic_light'] = 'yellow';
                        } else if (yellowCount === 1) {
                            finalData[format + '_traffic_light'] = 'green';
                        } else {
                            finalData[format + '_traffic_light'] = 'green';
                        }
                    }
                }
                if (format === 'Historial_laboral_porcentaje') {
                    if (finalData['Number_Of_Jobs_Under_3_Months'] >= 3) {
                        finalData[format + '_traffic_light'] = 'red';
                    } else {
                        finalData[format + '_traffic_light'] = 'green';
                    }
                    if (finalData['Semanas_Cotizadas'] === 'Acudir subdelegación' ||
                        finalData['Semanas_Cotizadas'].startsWith('0 semanas')) {
                        finalData[format + '_traffic_light'] = 'green';
                    }
                }
                if (format === 'SLcore') {
                    if (finalData['Numero_creditos_total'] === '0') {
                        finalData[format + '_traffic_light'] = 'green';
                    }
                    if (finalData['Lending_Recommendation']) {
                        finalData[format + '_traffic_light'] = 'red';
                    } else {
                        finalData[format + '_traffic_light'] = 'green';
                    }
                }
            }

            if (upperCompanyName === 'UNION RH' && format !== 'Historial_Legal_Mensaje') {
                if (finalData[format + '_traffic_light'] === 'green') {
                    finalData[format] = format === 'SLcore' ? 'Bueno' : "Recomendable";
                } else if (finalData[format + '_traffic_light'] === 'yellow') {
                    finalData[format] = 'Condicional';
                } else if (finalData[format + '_traffic_light'] === 'red') {
                    finalData[format] = 'No Conformidad Crítica';
                }
            }
        }
    } else {
        const gSectionFormats = ['Historial_Legal_Mensaje', 'Comportamiento_Personal_porcentaje'];
        const lightParams = processUserInfoCookie(upperCompanyName);

        for (const format of gSectionFormats) {
            if (format === 'Historial_Legal_Mensaje' && lightParams.light_on) {
                finalData[format + '_traffic_light'] = trafficLightSection(lightParams.oxxo_logic, lightParams.iconn_logic, lightParams.dhl_logic, finalData);
            } else if (format === 'Comportamiento_Personal_porcentaje' && lightParams.light_on_oxxo) {
                finalData[format + '_traffic_light'] = trafficLightSection(lightParams.oxxo_logic, lightParams.iconn_logic, lightParams.dhl_logic, finalData);
            }
        }
    }

    if (!isClientSpecial(upperCompanyName) && !['FARMACIAS DEL AHORRO', 'UNION RH'].includes(upperCompanyName)) {
        if (finalData['Grado_Demandas'] === 0 || finalData['Grado_Demandas'] === 1) {
            finalData['Historial_Legal_Mensaje_traffic_light'] = 'green';
        } else if (finalData['Grado_Demandas'] === 2 || finalData['Grado_Demandas'] === 3) {
            finalData['Historial_Legal_Mensaje_traffic_light'] = 'yellow';
        } else {
            finalData['Historial_Legal_Mensaje_traffic_light'] = 'red';
        }
    }
    return finalData;
}