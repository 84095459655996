import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Stack } from "@mui/material";
import InnerTable from "./InnerTable";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getCookiesList } from "../../../utils/cookiesHelper";
import { useTranslation } from "react-i18next";


export default function AccordainTableMain({
  personalization,
  data,
  sectionAData,
  sub_dicts,
  is_download = true,
}) {
  const { t } = useTranslation();
  const [cookieList, setCookieList] = useState([]);
  const [sub_dic, setSubDic] = useState({
    A: {
      I: true,
      II: true,
      III: true,
      IV: true,
      V: true,
      VI: true,
      VII: true,
      VIII: true,
      IX: true,
    },
    B: {
      I: true,
      II: true,
      III: true,
      IV: true,
      V: true,
      VI: true,
      VII: true,
      VIII: true,
      IX: true,
    },
    C: {
      I: true,
      II: true,
      III: true,
      IV: true,
      V: true,
      VI: true,
      VII: true,
      VIII: true,
      IX: true,
      X: true,
      XI: true,
      XII: true,
    },
    D: {
      I: true,
      II: true,
      III: true,
      IV: true,
      V: true,
      VI: true,
      VII: true,
      VIII: true,
      IX: true,
    },
    E: { I: true, II: true, III: true, IV: true, V: true },
    F: { I: true, II: true, III: true, IV: true, V: true, VI: true },
    G: { I: true, II: true, III: true, IV: true, V: true },
  });

  const selectedLanguage = localStorage.getItem("selectedLanguage");

  const [lightOnOXXO, setlightOnOXXO] = useState(false);
  const [iconn_logic, seticonn_logic] = useState(false);
  const [oxxo_logic, setoxxo_logic] = useState(false);
  const [banorte, setbanorte] = useState(false);
  const [lightOn, setlightOn] = useState(false);
  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");

    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);
  if (sub_dic) {
    const mydic = {
      I: 1,
      II: 2,
      III: 3,
      IV: 4,
      V: 5,
      VI: 6,
      VII: 7,
      VIII: 8,
      IX: 9,
      X: 10,
      XI: 11,
      XII: 12,
    };
    for (const key in sub_dic) {
      // let i = 1;

      for (const subkey in sub_dic[key]) {
        const sub_dic_key = `sub_${key.toLowerCase()}${mydic[subkey]}`;
        sub_dic[sub_dic_key] = sub_dic[key][subkey];
        // i++;
      }
    }
  } else {
    setSubDic(sub_dicts);
  }

  const navigate = useNavigate();
  const userInfo = Cookies.get("guestUserInfo");

  const pathhandlerDasboard = () => {
    if (userInfo) {
      navigate("/GuestDashboard");
    } else {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    setSubDic((prevState) => ({ ...personalization }));
  }, [cookieList]);

  const getResidenciaColoniaText = (key) => {
    // HTML string
    let htmlString;
    if (Array.isArray(data[key])) {
      htmlString = data[key].filter(item => item !== "").join("\n\n");
    } else {
      if (key == 'Universidad') {
        htmlString = data[key].replace('<br><br>', '\n\n');
      } else {
        htmlString = data[key];
      }
    }
    // Parse the HTML string using DOMParser
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(htmlString, "text/html");

    // Get the text content from the parsed HTML
    const textContent = parsedHTML.documentElement.textContent;
    // Add a space between the date and the following text
    const modifiedContent = textContent.replace(
      /(\d{2}\/\d{2}\/\d{4})(\d+ meses)/,
      "$1 $2"
    );

    return modifiedContent;
  };

  const getGreenBackgroundColor = (value) => {
    if (value.includes("%")) {
      const percentage = parseFloat(value);

      if (percentage > 75) {
        return "green";
      }
    } else {
      switch (value) {
        case "Excelente":
          return "green";
        default:
          return "#fff"; // default color if none of the conditions match
      }
    }
  };

  const getYellowBackgroundColor = (value) => {
    if (value.includes("%")) {
      const percentage = parseFloat(value);

      if (percentage >= 50 && percentage <= 75) {
        return "yellow";
      }
    } else {
      switch (value) {
        case "Bueno":
        case "Regular":
        case "Indeterminado por homonimias":
          return "yellow";
        default:
          return "#fff"; // default color if none of the conditions match
      }
    }
  };

  const getRedBackgroundColor = (value) => {
    if (value.includes("%")) {
      const percentage = parseFloat(value);

      if (percentage < 50) {
        return "red";
      }
    } else {
      switch (value) {
        case "Discutible":
        case "Dudoso":
          return "red";
        default:
          return "#fff"; // default color if none of the conditions match
      }
    }
  };

  return (
    <>
      <TableContainer sx={{ borderRadius: 0, boxShadow: 0 }} component={Paper}>
        <Table sx={{ borderRadius: 0 }} aria-label="simple table">
          <TableBody sx={{ borderRadius: 0 }}>
            {Object.entries(sectionAData).map(([key, section], rowIndex) => (
              <>
                {sub_dic[section.sub] &&
                  section.sub !== "sub_c9" &&
                  (section.titlealphabet != "G" || section.sub == "sub_g6") ? (
                  <TableRow
                    sx={{
                      borderRadius: 0,
                      "&:last-child td, &:last-child th": {
                        border: 0,
                        borderRadius: 0,
                      },
                      backgroundColor:
                      section.sub == "sub_g6" ? "inherit" : rowIndex % 2 === 1 ? "#f5faff" : "inherit",
                    }}
                  >
                    {section.inner_table["status"]}
                    {section.inner_table["status"] ? (
                      <TableCell
                        colSpan={section.sub == "sub_g6" ? 3 : 2}
                        sx={{
                          width: "60%",
                          borderBottom: "5px solid #ffffff !important",
                          borderRadius: 0,
                          fontSize: { xs: "1.75vw", md: "0.875rem" },
                        }}
                        align="left"
                      >
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Typography
                            sx={{
                              color: "primary.main",
                              width: "50%",
                              fontSize: { xs: "1.75vw", md: 14 },
                              fontFamily: "Gilroy-Medium",
                            }}
                          >
                            {t(`${section.title}`)}
                          </Typography>

                          <Typography
                            sx={{
                              color: "primary.main",
                              width: "50%",
                              fontSize: { xs: "1.75vw", md: 12 },
                              fontFamily: "Gilroy-Medium",
                              textAlign: "center",
                            }}
                          >
                            {data[section.format]}
                          </Typography>
                        </Box>
                        {sub_dic[section.sub] &&
                          data[section.inner_table["data"]] &&
                          data[section.inner_table["data"]].length > 0 ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              mt: 0,
                            }}
                          >
                            <InnerTable
                              tableData={data[section.inner_table["data"]]}
                              dataType={section.inner_table["type"]}
                              isDownload={section.inner_table["type"] == "geoloc" ? false : is_download}
                              hideButton={true}
                            />
                          </Box>
                        ) : null}
                      </TableCell>
                    ) : (
                      <>
                        <TableCell
                          sx={{
                            width: "30%",
                            borderBottom: "5px solid #ffffff",
                            borderRadius: 0,
                            p: { xs: "5px", md: "16px" },
                            fontSize: { xs: "1.75vw", md: "0.875rem" },
                          }}
                          align="left"
                        >
                          <Typography
                            sx={{
                              color: "primary.main",
                              fontSize: { xs: "1.75vw", md: 14 },
                              fontFamily: "Gilroy-Medium",
                            }}
                          >
                            {t(`${section["title"]}`)}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            width: "30%",
                            borderBottom: "5px solid #ffffff",
                            borderRadius: 0,
                            p: { xs: "5px", md: "16px" },
                            fontSize: { xs: "1.75vw", md: "0.875rem" },
                          }}
                          align="left"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection:
                                section.format === "Cedula" ? "column" : "row",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          >
                            {section.format === "Cedula" ? (
                              data["CedulaList"] &&
                                data["CedulaList"].length ? (
                                data["CedulaList"].map((item) => (
                                  <>
                                    <Typography
                                      sx={{
                                        color: "#000000",
                                        fontSize: { xs: "1.75vw", md: 12 },
                                        fontFamily: "Gilroy-Medium",
                                      }}
                                    >
                                      {item.Cedula} / {item.Title || item.Título}
                                    </Typography>
                                    <br />
                                  </>
                                ))
                              ) : (
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontSize: { xs: "1.75vw", md: 12 },
                                    fontFamily: "Gilroy-Medium",
                                  }}
                                >
                                  {data["Cedula"]}
                                </Typography>
                              )
                            ) : (
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: { xs: "1.75vw", md: 12 },
                                  fontFamily: "Gilroy-Medium",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {data[section.format] !== undefined &&
                                  data[section.format] !== null
                                  ? getResidenciaColoniaText([section.format])
                                  : data[section.format] === null
                                    ? " "
                                    : section.format}
                                {section.by["status"] ? (
                                  <Typography
                                    variant="span"
                                    sx={{
                                      color: "warning.main",
                                      fontSize: { xs: "1.75vw", md: 12 },
                                      fontFamily: "Gilroy-Medium",
                                      ml: "4px",
                                    }}
                                  >
                                    /{" "}
                                    {getResidenciaColoniaText(
                                      section.by["value"]
                                    )}
                                  </Typography>
                                ) : null}
                              </Typography>
                            )}
                          </Box>
                        </TableCell>
                      </>
                    )}

                    <TableCell
                      sx={{
                        width: "20%",
                        backgroundColor: "rgba(0, 127, 255, 0.2)",
                        borderBottom: "5px solid #ffffff !important",
                        borderRight: "5px solid #ffffff !important",
                        borderRadius: 0,
                        p: { xs: "5px", md: "16px" },
                        fontSize: { xs: "1.75vw", md: "0.875rem" },
                        whiteSpace: "break-spaces",
                      }}
                      align="center"
                    >
                      {" "}
                      {t(`${section.concepto}`)}
                    </TableCell>

                    <TableCell
                      sx={{
                        width: "20%",
                        backgroundColor: "rgba(0, 127, 255, 0.2)",
                        borderRadius: 0,
                        borderBottom: "5px solid #ffffff !important",
                        p: { xs: "5px", md: "16px" },
                        fontSize: { xs: "1.75vw", md: "0.875rem" },
                      }}
                      align="left"
                    >
                      <ul>
                        {/* <li>item 1</li>
                        <li>item 2</li> */}
                        {section.resultado.map((item) => (
                          <li style={{ listStyle: "none" }}> {t(`${item}`)}</li>
                        ))}
                      </ul>
                    </TableCell>
                  </TableRow>
                ) : null}
                {section.format === "colonia" && (
                  <TableRow sx={{ borderRadius: 0 }}>
                    <TableCell colSpan={5} sx={{ p: 0 }}>
                      {data.Geo_Location_Map !== '' ? <img
                        src={'data:image/png;base64,' + data.Geo_Location_Map}
                        alt="Dynamic Static Map"
                        width="100%"
                        style={{ border: 0 }}
                      /> : data.Geo_Location_Exists ? <Typography variant="h6" sx={{ textAlign: "center" }}>{t("thanks.NoCoordinates")}</Typography> : null}
                    </TableCell>
                  </TableRow>
                )}

                {sub_dic[section.sub] &&
                  section.sub == "sub_c9" &&
                  section.titlealphabet != "G" ? (
                  <>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        sx={{
                          width: "100%",
                          borderBottom: "5px solid #ffffff",
                          borderRadius: 0,
                          p: { xs: "5px", md: "16px" },
                          fontSize: { xs: "1.75vw", md: "0.875rem" },
                        }}
                      >
                        <Table
                          sx={{ borderRadius: 0 }}
                          aria-label="simple table"
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                width: "50%",
                                borderRadius: 0,
                                border: 0,
                                p: 0,

                                fontSize: { xs: "1.75vw", md: "0.875rem" },
                              }}
                              align="center"
                            >
                              <Box sx={{ display: "flex" }}>
                                <Typography
                                  sx={{
                                    color: "#4165F6",
                                    fontSize: { xs: "1.75vw", md: 14 },
                                  }}
                                  variant="body1"
                                >
                                  {t("thanks.tachometer")}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "50%",
                                borderRadius: 0,
                                border: 0,
                                p: 0,
                                fontSize: { xs: "1.75vw", md: "0.875rem" },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  pl: "20px",
                                }}
                              >
                                <Box
                                  component="span"
                                  sx={{
                                    color: "#204603",
                                    margin: "2px",
                                    fontWeight: 600,
                                    fontSize: { xs: "1.75vw", md: 14 },
                                  }}
                                >
                                  {t("thanks.GRADE")}
                                </Box>

                                {data["Grado_Demandas"] === 0 && (
                                  <Box
                                    component="span"
                                    sx={{
                                      fontWeight: 600,
                                      color: "#4165F6",
                                      margin: "2px",
                                      fontSize: { xs: "1.75vw", md: 14 },
                                    }}
                                  >
                                    {t("thanks.BAJO")}
                                  </Box>
                                )}
                                {data["Grado_Demandas"] === 1 && (
                                  <Box
                                    component="span"
                                    sx={{
                                      fontWeight: 600,
                                      color: "#204603",
                                      margin: "2px",
                                      fontSize: { xs: "1.75vw", md: 14 },
                                    }}
                                  >
                                    {t("thanks.MÍNIMO")}
                                  </Box>
                                )}
                                {data["Grado_Demandas"] === 2 && (
                                  <Box
                                    component="span"
                                    sx={{
                                      fontWeight: 600,
                                      color: "#204603",
                                      margin: "2px",
                                      fontSize: { xs: "1.75vw", md: 14 },
                                    }}
                                  >
                                    {t("thanks.PREVENTIVO")}
                                  </Box>
                                )}
                                {data["Grado_Demandas"] === 3 && (
                                  <Box
                                    component="span"
                                    sx={{
                                      fontWeight: 600,
                                      color: "#204603",
                                      margin: "2px",
                                      fontSize: { xs: "1.75vw", md: 14 },
                                    }}
                                  >
                                    {t("thanks.ALERTA")}
                                  </Box>
                                )}
                                {data["Grado_Demandas"] === 4 && (
                                  <Box
                                    component="span"
                                    sx={{
                                      fontWeight: 600,
                                      color: "#204603",
                                      margin: "2px",
                                      fontSize: { xs: "1.75vw", md: 14 },
                                    }}
                                  >
                                    {t("thanks.ALTO")}
                                  </Box>
                                )}
                                <Box
                                  component="span"
                                  sx={{
                                    color: "#204603",
                                    margin: "2px",
                                    fontWeight: 600,
                                    fontSize: { xs: "1.75vw", md: 14 },
                                  }}
                                >
                                  {t("thanks.FOUND")}
                                </Box>

                                {(data["Grado_Demandas"] === 0 ||
                                  data["Grado_Demandas"] === 1 ||
                                  data["Grado_Demandas"] === 2 ||
                                  data["Grado_Demandas"] === 3 ||
                                  data["Grado_Demandas"] === 4) && (
                                    <Box
                                      component="span"
                                      sx={{
                                        fontWeight: "bold",
                                        color: "#4165F6",
                                        margin: "2px",
                                        fontSize: { xs: "1.75vw", md: 22 },
                                      }}
                                    >
                                      {data["Grado_Demandas"]}
                                    </Box>
                                  )}
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                width: "50%",
                                borderRadius: 0,
                                p: { xs: "5px", md: "16px" },
                                fontSize: { xs: "1.75vw", md: "0.875rem" },
                              }}
                              align="center"
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                {data["Grado_Demandas"] === 0 && (
                                  <Box
                                    component="img"
                                    src={
                                      selectedLanguage === "en"
                                        ? "https://searchlook-new.s3.amazonaws.com/compartir/Techometer/English/Low.png"
                                        : "https://searchlook-new.s3.amazonaws.com/compartir/Techometer/Spanish/Bajo.png"
                                    }
                                    alt="Grado 0"
                                    sx={{
                                      width: "90%",
                                      height: "auto",
                                      display: "block",
                                      marginTop: "40px",
                                      marginLeft: "0px",
                                      //  transform: "scaleX(-1)", // Mirror the image horizontally
                                    }}
                                  />
                                )}
                                {data["Grado_Demandas"] === 1 && (
                                  <Box
                                    component="img"
                                    src={
                                      selectedLanguage === "en"
                                        ? "https://searchlook-new.s3.amazonaws.com/compartir/Techometer/English/Minimum.png"
                                        : "https://searchlook-new.s3.amazonaws.com/compartir/Techometer/Spanish/Mínimo.png"
                                    }
                                    alt="Grado 0"
                                    sx={{
                                      width: "90%",
                                      height: "auto",
                                      display: "block",
                                      marginTop: "40px",
                                      marginLeft: "0px",
                                    }}
                                  />
                                )}
                                {data["Grado_Demandas"] === 2 && (
                                  <Box
                                    component="img"
                                    src={
                                      selectedLanguage === "en"
                                        ? "https://searchlook-new.s3.amazonaws.com/compartir/Techometer/English/Preventive.png"
                                        : "https://searchlook-new.s3.amazonaws.com/compartir/Techometer/Spanish/Preventivo.png"
                                    }
                                    alt="Grado 0"
                                    sx={{
                                      width: "90%",
                                      height: "auto",
                                      display: "block",
                                      marginTop: "40px",
                                      marginLeft: "0px",
                                    }}
                                  />
                                )}
                                {data["Grado_Demandas"] === 3 && (
                                  <Box
                                    component="img"
                                    src={
                                      selectedLanguage === "en"
                                        ? "https://searchlook-new.s3.amazonaws.com/compartir/Techometer/English/Alert.png"
                                        : "https://searchlook-new.s3.amazonaws.com/compartir/Techometer/Spanish/Alerta.png"
                                    }
                                    alt="Grado 0"
                                    sx={{
                                      width: "90%",
                                      height: "auto",
                                      display: "block",
                                      marginTop: "40px",
                                      marginLeft: "0px",
                                    }}
                                  />
                                )}
                                {data["Grado_Demandas"] === 4 && (
                                  <Box
                                    component="img"
                                    src={
                                      selectedLanguage === "en"
                                        ? "https://searchlook-new.s3.amazonaws.com/compartir/Techometer/English/High.png"
                                        : "https://searchlook-new.s3.amazonaws.com/compartir/Techometer/Spanish/Alto.png"
                                    }
                                    alt="Grado 0"
                                    sx={{
                                      width: "90%",
                                      height: "auto",
                                      display: "block",
                                      marginTop: "40px",
                                      marginLeft: "0px",
                                    }}
                                  />
                                )}
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "50%",
                                borderRadius: 0,
                                p: { xs: "5px", md: "16px" },
                                fontSize: { xs: "1.75vw", md: "0.875rem" },
                              }}
                            >
                              <Table
                                sx={{ minWidth: "100%", borderRadius: 0 }}
                                aria-label="simple table"
                              >
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      width: "33%",
                                      borderRadius: 0,
                                      border: 0,
                                      p: "5px",
                                      fontSize: { xs: "1.5vw", md: "0.875rem" },
                                    }}
                                    align="left"
                                  >
                                    <Box
                                      sx={{
                                        width: { xs: "15vw", md: "150px" },
                                        height: { xs: "13vw", md: "120px" },
                                        backgroundColor: "#f1f4fb",
                                        borderRadius: "12px",
                                        position: "relative",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top: 0,
                                          bottom: 0,
                                          left: 0,
                                          width: "10px",
                                          backgroundColor: "#bee0e0",
                                          borderTopLeftRadius: "12px",
                                          borderBottomLeftRadius: "12px",
                                        }}
                                      ></Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          ml: 2.5,
                                          flexDirection: "column",
                                          height: "100%",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            mb: "8px",
                                            fontSize: { xs: "1.5vw", md: 16 },
                                            color: "#204603",
                                            fontWeight: 600,
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t(
                                            "authorizationpanel.rightContent.subtitle.Civil"
                                          )}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: { xs: "1.5vw", md: 12 },
                                            color: "#777777",
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t("authorizationpanel.rightContent.subtitle.Demands")}: {" "} {data["Demandas_Civiles"]}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: { xs: "1.5vw", md: 12 },
                                            color: "#777777",
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t("authorizationpanel.rightContent.subtitle.Last Mov")}: {" "} {data["Demandas_Civiles_Ult_Mov"]}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: "33%",
                                      borderRadius: 0,
                                      border: 0,
                                      p: "5px",
                                      fontSize: { xs: "1.5vw", md: "0.875rem" },
                                    }}
                                    align="left"
                                  >
                                    <Box
                                      sx={{
                                        width: { xs: "15vw", md: "150px" },
                                        height: { xs: "13vw", md: "120px" },
                                        backgroundColor: "#f1f4fb",
                                        borderRadius: "12px",
                                        position: "relative",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top: 0,
                                          bottom: 0,
                                          left: 0,
                                          width: "10px",
                                          backgroundColor: "#f8c17d",
                                          borderTopLeftRadius: "12px",
                                          borderBottomLeftRadius: "12px",
                                        }}
                                      ></Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          ml: 2.5,
                                          flexDirection: "column",
                                          height: "100%",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            mb: "8px",
                                            fontSize: { xs: "1.5vw", md: 16 },
                                            color: "#204603",
                                            fontWeight: 600,
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t(
                                            "authorizationpanel.rightContent.subtitle.Criminal"
                                          )}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: { xs: "1.5vw", md: 12 },
                                            color: "#777777",
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t("authorizationpanel.rightContent.subtitle.Demands")}: {" "} {data["Demandas_Penales"]}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: { xs: "1.5vw", md: 12 },
                                            color: "#777777",
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t("authorizationpanel.rightContent.subtitle.Last Mov")}: {" "} {data["Demandas_Penales_Ult_Mov"]}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: "33%",
                                      borderRadius: 0,
                                      border: 0,
                                      p: "5px",
                                      fontSize: { xs: "1.5vw", md: "0.875rem" },
                                    }}
                                    align="left"
                                  >
                                    <Box
                                      sx={{
                                        width: { xs: "15vw", md: "150px" },
                                        height: { xs: "13vw", md: "120px" },
                                        backgroundColor: "#f1f4fb",
                                        borderRadius: "12px",
                                        position: "relative",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top: 0,
                                          bottom: 0,
                                          left: 0,
                                          width: "10px",
                                          backgroundColor: "#bee0e0",
                                          borderTopLeftRadius: "12px",
                                          borderBottomLeftRadius: "12px",
                                        }}
                                      ></Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          ml: 2.5,
                                          flexDirection: "column",
                                          height: "100%",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            mb: "8px",
                                            fontSize: { xs: "1.5vw", md: 16 },
                                            color: "#204603",
                                            fontWeight: 600,
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t(
                                            "authorizationpanel.rightContent.subtitle.Administrative"
                                          )}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: { xs: "1.5vw", md: 12 },
                                            color: "#777777",
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t("authorizationpanel.rightContent.subtitle.Demands")}: {" "} {data["Demandas_Administrativas"]}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: { xs: "1.5vw", md: 12 },
                                            color: "#777777",
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t("authorizationpanel.rightContent.subtitle.Last Mov")}: {" "} {data["Demandas_Administrativas_Ult_Mov"]}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      width: "33%",
                                      borderRadius: 0,
                                      border: 0,
                                      p: "5px",
                                      fontSize: { xs: "1.5vw", md: "0.875rem" },
                                    }}
                                    align="left"
                                  >
                                    <Box
                                      sx={{
                                        width: { xs: "15vw", md: "150px" },
                                        height: { xs: "13vw", md: "120px" },
                                        backgroundColor: "#f1f4fb",
                                        borderRadius: "12px",
                                        position: "relative",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top: 0,
                                          bottom: 0,
                                          left: 0,
                                          width: "10px",
                                          backgroundColor: "#bee0e0",
                                          borderTopLeftRadius: "12px",
                                          borderBottomLeftRadius: "12px",
                                        }}
                                      ></Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          ml: 2.5,
                                          flexDirection: "column",
                                          height: "100%",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            mb: "8px",
                                            fontSize: { xs: "1.5vw", md: 16 },
                                            color: "#204603",
                                            fontWeight: 600,
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t(
                                            "authorizationpanel.rightContent.subtitle.Family"
                                          )}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: { xs: "1.5vw", md: 12 },
                                            color: "#777777",
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t("authorizationpanel.rightContent.subtitle.Demands")}: {" "} {data["Demandas_Familiares"]}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: { xs: "1.5vw", md: 12 },
                                            color: "#777777",
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t("authorizationpanel.rightContent.subtitle.Last Mov")}: {" "} {data["Demandas_Familiares_Ult_Mov"]}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: "33%",
                                      borderRadius: 0,
                                      border: 0,
                                      p: "5px",
                                      fontSize: { xs: "1.5vw", md: "0.875rem" },
                                    }}
                                    align="left"
                                  >
                                    <Box
                                      sx={{
                                        width: { xs: "15vw", md: "150px" },
                                        height: { xs: "13vw", md: "120px" },
                                        backgroundColor: "#f1f4fb",
                                        borderRadius: "12px",
                                        position: "relative",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top: 0,
                                          bottom: 0,
                                          left: 0,
                                          width: "10px",
                                          backgroundColor: "#f8c17d",
                                          borderTopLeftRadius: "12px",
                                          borderBottomLeftRadius: "12px",
                                        }}
                                      ></Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          ml: 2.5,
                                          flexDirection: "column",
                                          height: "100%",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            mb: "8px",
                                            fontSize: { xs: "1.5vw", md: 16 },
                                            color: "#204603",
                                            fontWeight: 600,
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t(
                                            "authorizationpanel.rightContent.subtitle.Labor"
                                          )}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: { xs: "1.5vw", md: 12 },
                                            color: "#777777",
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t("authorizationpanel.rightContent.subtitle.Demands")}: {" "} {data["Demandas_Laborales"]}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: { xs: "1.5vw", md: 12 },
                                            color: "#777777",
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                        >
                                          {t("authorizationpanel.rightContent.subtitle.Last Mov")}: {" "} {data["Demandas_Laborales_Ult_Mov"]}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </Table>
                            </TableCell>
                          </TableRow>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </>
                ) : null}

                {sub_dic[section.sub] && section.titlealphabet == "G" && section.sub != "sub_g6" ? (
                  // row with lights + text
                  <TableRow
                    sx={{
                      borderRadius: 0,
                      "&:last-child td, &:last-child th": {
                        border: 0,
                        borderRadius: 0,
                      },
                      // backgroundColor: rowIndex % 2 === 1 ? '#f5faff' : 'inherit',
                    }}
                  >
                    <TableCell
                      colSpan={2}
                      sx={{
                        width: "30%",
                        borderBottom: "5px solid #ffffff",
                        borderRadius: 0,
                        p: { xs: "5px", md: "16px" },
                        fontSize: { xs: "1.75vw", md: "0.875rem" },
                      }}
                      align="left"
                    >
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{
                            color: "primary.main",
                            width: "60%",
                            fontSize: { xs: "1.75vw", md: 14 },
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          {t(`${section.title}`)}
                        </Typography>
                      </Box>
                    </TableCell>

                    {data[section.format + "_traffic_light"] ? (
                      <TableCell
                        sx={{
                          width: "30%",
                          borderBottom: "5px solid #ffffff",
                          borderRadius: 0,
                          p: { xs: "5px", md: "16px" },
                          fontSize: { xs: "1.75vw", md: "0.875rem" },
                        }}
                        align="center"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ width: "50%" }}>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: { xs: "1.75vw", md: 12 },
                                fontFamily: "Gilroy-Medium",
                              }}
                            >
                              {cookieList[13] === "FARMACIAS DEL AHORRO" ? null : data[section.format]}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: { xs: "100%", md: "50%" },
                              justifyContent: "center",
                              alignItems: "center",
                              columnGap: 1,
                            }}
                          >
                            {data[section.format + "_traffic_light"] ===
                              "green" ? (
                              <Box
                                sx={{
                                  width: { xs: 10, md: 25 },
                                  height: { xs: 10, md: 25 },
                                  border: "1px solid #ececed",
                                  backgroundColor: "green",

                                  display: "flex",
                                  borderRadius: 50,
                                }}
                              ></Box>
                            ) : (
                              <Box
                                sx={{
                                  width: { xs: 10, md: 25 },
                                  height: { xs: 10, md: 25 },
                                  border: "1px solid #ececed",
                                  backgroundColor: "#ffffff",

                                  display: "flex",
                                  borderRadius: 50,
                                }}
                              ></Box>
                            )}
                            {data[section.format + "_traffic_light"] ===
                              "yellow" ? (
                              <Box
                                sx={{
                                  width: { xs: 10, md: 25 },
                                  height: { xs: 10, md: 25 },
                                  border: "1px solid #ececed",
                                  backgroundColor: "yellow",

                                  display: "flex",
                                  borderRadius: 50,
                                }}
                              ></Box>
                            ) : (
                              <Box
                                sx={{
                                  width: { xs: 10, md: 25 },
                                  height: { xs: 10, md: 25 },
                                  border: "1px solid #ececed",
                                  backgroundColor: "#ffffff",

                                  display: "flex",
                                  borderRadius: 50,
                                }}
                              ></Box>
                            )}
                            {data[section.format + "_traffic_light"] ===
                              "red" ||
                              data[section.format + "_traffic_light"] ===
                              "grid" ? (
                              <Box
                                sx={{
                                  width: { xs: 10, md: 25 },
                                  height: { xs: 10, md: 25 },
                                  border: "1px solid #ececed",
                                  backgroundColor: "red",

                                  display: "flex",
                                  borderRadius: 50,
                                }}
                              ></Box>
                            ) : (
                              <Box
                                sx={{
                                  width: { xs: 10, md: 25 },
                                  height: { xs: 10, md: 25 },
                                  border: "1px solid #ececed",
                                  backgroundColor: "#ffffff",

                                  display: "flex",
                                  borderRadius: 50,
                                }}
                              ></Box>
                            )}
                          </Box>
                        </Box>
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          width: "30%",
                          borderBottom: "5px solid #ffffff",
                          borderRadius: 0,
                        }}
                        align="center"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            columnGap: 5,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: { xs: "1.75vw", md: 12 },
                              fontFamily: "Gilroy-Medium",
                              textAlign: "center",
                              minWidth: 100,
                              maxWidth: 100,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {data[section.format]}
                          </Typography>
                          {/* <Stack
                            direction="row"
                            alignItems="center"
                            columnGap={1}
                          >
                            <Box
                              sx={{
                                width: { xs: 10, md: 25 },
                                height: { xs: 10, md: 25 },
                                border: "1px solid #ececed",
                                backgroundColor: getGreenBackgroundColor(
                                  data[section.format]
                                ),

                                display: "flex",
                                borderRadius: "50%",
                              }}
                            />
                            <Box
                              sx={{
                                width: { xs: 10, md: 25 },
                                height: { xs: 10, md: 25 },
                                border: "1px solid #ececed",
                                backgroundColor: getYellowBackgroundColor(
                                  data[section.format]
                                ),

                                display: "flex",
                                borderRadius: "50%",
                              }}
                            />
                            <Box
                              sx={{
                                width: { xs: 10, md: 25 },
                                height: { xs: 10, md: 25 },
                                border: "1px solid #ececed",
                                backgroundColor: getRedBackgroundColor(
                                  data[section.format]
                                ),

                                display: "flex",
                                borderRadius: "50%",
                              }}
                            />
                          </Stack> */}
                        </Box>
                      </TableCell>
                    )}

                    <TableCell
                      sx={{
                        width: "20%",
                        backgroundColor: "rgba(0, 127, 255, 0.2)",
                        borderBottom: "5px solid #ffffff !important",
                        borderRight: "5px solid #ffffff !important",
                        borderRadius: 0,
                        p: { xs: "5px", md: "16px" },
                        fontSize: { xs: "1.75vw", md: "0.875rem" },
                      }}
                      align="center"
                    >
                      {t(`${section.concepto}`)}
                    </TableCell>

                    <TableCell
                      sx={{
                        width: "20%",
                        backgroundColor: "rgba(0, 127, 255, 0.2)",
                        borderRadius: 0,
                        borderBottom: "5px solid #ffffff !important",
                        p: { xs: "5px", md: "16px" },
                        fontSize: { xs: "1.75vw", md: "0.875rem" },
                      }}
                      align="left"
                    >
                      <ul>
                        {section.resultado.map((item) => (
                          <li style={{ listStyle: "none" }}> {t(`${item}`)}</li>
                        ))}
                      </ul>
                    </TableCell>
                  </TableRow>
                ) : null}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
